import React, { Component } from "react"

import Layout from "../components/layout"
import Footer from '../components/footer';
// import Wizard from "../components/wizard"
import conIcon from "../images/cong-icon.svg"
import bottomLine from "../images/congratulation-line.svg"
// import SEO from "../components/seo"
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';


class ThanksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
       
    }
    componentDidMount() {
        this.nameInput.focus();
    }
    render() {
        return (
            <Layout layoutType="main">
                <input className="hidox" ref={(input) => { this.nameInput = input; }} />
                <section className="congratulation-section">
                    {/* <Wizard></Wizard> */}
                    <div className="custom-container main-congratulation-container">
                        <div className="body-content congratulation-content ">
                            <div className="congratulation-container">
                            <img src={conIcon} alt="" />
                            <h1>Thanks!</h1>
                            <h4>You have been successfully registered.</h4>
                        </div>
                    </div>
                </div>
                </section>
                <div className="vehicle-bottom-line bottom-line">
                    <img src={bottomLine} alt="line" />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        step2response: state.register.step2response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ThanksPage);
